body {
    margin: 10px auto;
    max-width: 980px;
    font-family: Arial, Helvetica, Verdana, sans-serif;
    color: #654321;
    font-size: 16px;
    border: 1px solid black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    color:  #654321;
}

h1 {
    font-size: 20px;
}

h2 {
    font-size: 16px;
}

h3 {
    font-size: 14px;
}
