.spinner-box {
}
.spinner {
    position: relative;
    display: inline-block;
    width: fit-content;
    z-index: 1000;
    vertical-align: middle;
    padding: 0;
    margin: -6px 0;
}

.spinner-box h2 {
    display: inline-block;
}

/* generated by https://loading.io/ */
@keyframes ldio-528ng4tcaf {
    0% { transform: translate(6px,40px) scale(0); }
    25% { transform: translate(6px,40px) scale(0); }
    50% { transform: translate(6px,40px) scale(1); }
    75% { transform: translate(40px,40px) scale(1); }
    100% { transform: translate(74px,40px) scale(1); }
}
@keyframes ldio-528ng4tcaf-r {
    0% { transform: translate(74px,40px) scale(1); }
    100% { transform: translate(74px,40px) scale(0); }
}
@keyframes ldio-528ng4tcaf-c {
    0% { background: #322923 }
    25% { background: #d7a05b }
    50% { background: #896038 }
    75% { background: #513e2e }
    100% { background: #322923 }
}
.ldio-528ng4tcaf div {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: translate(40px,40px) scale(1);
    background: #322923;
    animation: ldio-528ng4tcaf 1.7857142857142856s infinite cubic-bezier(0,0.5,0.5,1);
}
.ldio-528ng4tcaf div:nth-child(1) {
    background: #513e2e;
    transform: translate(74px,40px) scale(1);
    animation: ldio-528ng4tcaf-r 0.4464285714285714s infinite cubic-bezier(0,0.5,0.5,1), ldio-528ng4tcaf-c 1.7857142857142856s infinite step-start;
}.ldio-528ng4tcaf div:nth-child(2) {
    animation-delay: -0.4464285714285714s;
    background: #322923;
}.ldio-528ng4tcaf div:nth-child(3) {
    animation-delay: -0.8928571428571428s;
    background: #513e2e;
}.ldio-528ng4tcaf div:nth-child(4) {
    animation-delay: -1.3392857142857142s;
    background: #896038;
}.ldio-528ng4tcaf div:nth-child(5) {
    animation-delay: -1.7857142857142856s;
    background: #d7a05b;
}
.loadingio-spinner-ellipsis-gnzf74rssp {
    width: 54px;
    height: 54px;
    display: inline-block;
    overflow: hidden;
    background: none;
}
.ldio-528ng4tcaf {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.54);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-528ng4tcaf div { box-sizing: content-box; }
