.updates {
    color: purple;
}

.box {
    border: 2px solid purple;
    text-align: center;
    padding: 5px;
}

.beta ul {
    margin: 2px 0;
}

.navbar {
    padding-right: 35px;
}    

.navbar ul {
    list-style-type: none;
    overflow: hidden;
}

.navbar li {
    float: right;
    padding: 2px 0 2px 20px;
    font-size: 20px;
    display: block;
    font-weight: normal;
}

.navbar li a {
    text-decoration: none;
}

.navbar li a:hover {
    text-decoration: underline;
}

.cleaner{
    clear: left; line-height: 0; height: 0;
}

.clear-right {
    clear: right; line-height: 0; height: 0;
}

.clear-both {
    clear: both;
}

.purple {
    color: purple;
}

.spacer {
    display: inline-block;
    width: 10px;
}

.space-left {
    padding-left: 5px;
}

.space-top {
    margin-top: 5px;
}

.normal {
    font-weight: normal;
}

.page-header {
    background: url("/public/pure-gas-header.png");
    height: 200px;
}

.content {
    padding: 0 10px;
}

.notice {
    font-style: italic;
}

.welcome {
    font-weight: bold;
}

.message {
    color: darkred;
    font-weight: bold;
    font-size: 16px;
    padding: 10px;
    width: fit-content;
    margin: 10px auto;
    text-align: center;
}

.thanks {
    color: purple;
    font-weight: bold;
    padding: 5px 0;
}

.error {
    border: 1px solid darkred;
    padding: 10px;
    width: fit-content;
    color: darkred;
    margin: 5px auto;
}

.loading {
    padding: 20px;
}

form.google-search {
    float: right;
    margin: 20px;
}

form.google-search input {
    color: gray;
}

.page-footer {
    border-top: 1px solid black;
    margin-top: 5px;
    padding: 5px 5px 0 5px;
    height: 35px;
    font-size: 12px;
}

.cc-logo {
    float: left;
}

.license {
    float: left;
    margin-left: 10px;
}

.attrib {
    float: left;
    width: 140px;
    margin-left: 60px;
    text-align: center;
}

.mobile-app {
    float: right;
    text-align: center;
    padding: 0 10px;
}

.station-rightside {
    float: right;
    margin: 5px;
}

.info-window {
    width: fit-content;
}

.station-link {
    padding-bottom: 10px;
    font-style: italic;
}

.map-link {
    padding-left: 20px;
    font-weight: normal;
    font-style: italic;
}

.station-count {
    margin: 5px 20px;
    text-align: center;
}

.state-name {
    padding: 10px 0;
    float: left;
    font-size: 16px;
    font-weight: bold;
}

.state-link {
    padding: 10px;
    float: left;
    font-style: italic;
}

.add-button {
    padding-top: 10px;
    float: right;
}

.add-button button {
    color: purple;
}

.state-list {
    text-align: center;
    background-color: #FEDCBA;
    border: 1px solid #654321;
    padding: 1px;
    margin: 0;
}

.state-list button {
    margin: 1px 1px;
    font-size: 10px;
}

.station {
    font-size: 12px;
    border-bottom: 1px solid #654321;
    word-wrap: break-word;
}

.station-list-header {
    font-weight: bold;
    border-bottom: 2px solid #654321;
}

.station .state {
    padding-top: 5px;
    font-weight: bold;
}

.station input {
    margin: 0;
}

/* --- These must add to 100% --- */
.station .checkbox {
    padding-top: 5px;
    width: 2%;
    float: left;
}
.station .city {
    padding-top: 5px;
    width: 13%;
    float: left;
    font-weight: bold;
}
.station .brand {
    padding-top: 5px;
    width: 12%;
    float: left;
}
.station .octanes {
    padding-top: 5px;
    width: 7%;
    float: left;
    text-align: center;
    font-weight: bold;
}
.station .name {
    padding-top: 5px;
    width: 21%;
    float: left;
}
.station .streetaddress {
    padding-top: 5px;
    width: 23%;
    float: left;
}
.station .phone {
    padding-top: 5px;
    width: 10%;
    float: left;
}
.station .lastupdate {
    padding-top: 5px;
    width: 8%;
    float: left;
}
.station .view {
    width: 4%;
    padding: 2px 0;
    float: left;
    text-align: center;
}
/* ----------------------------- */

.station .octanes .octane {
    padding:0 2px;
}
.station .view button {
    font-size: 11px;
}

.station-top-border {
    border-top: 2px solid #654321;
}

.station-view {
    font-size: 13px;
    padding-bottom: 5px;
    display: block;
    border-bottom: 2px solid #654321;
}

.station-view .gps-display {
    padding: 5px 0;
}

.station-view .gps-missing {
    padding: 5px 0;
}

.station-view .kml-icon {
    display: inline-block;
    text-align: center;
}

.station-view .gm-icon img {
    vertical-align: middle;
}

.station-view .gm-icon {
    display: inline-block;
    text-align: center;
}

.station-view .kml-icon img {
    vertical-align: middle;
}

.station-form {
    background-color: #FEDCBA;
    width: 410px;
    padding: 10px;
    margin: 10px auto;
}

.update {
    color: darkgreen;
}
.add {
    color: purple;
}
.remove {
    color: darkred;
}
.cancel {
    color: black;
}

.update-trim {
    border: 2px solid darkgreen;
}

.add-trim {
    border: 2px solid purple;
}

.remove-trim {
    border: 2px solid darkred;
}


.station-form label {
    display: block;
    padding: 5px 0;
    font-weight: bold;
}

.station-form div {
    padding: 5px 0;
}

.station-form input {
    width: 400px;
}

.station-form textarea {
    font-family: Arial, Helvetica, Verdana, sans-serif;
    font-size: 14px;
    width: 400px;
    height: 2em;
}

button.update-choice {
    color: darkgreen;
    margin: 0 5px;
}

button.remove-choice {
    color: darkred;
    margin: 0 5px;
}

button.update {
    font-weight: bold;
    padding: 10px;
}
button.remove {
    font-weight: bold;
    padding: 10px;
}
button.add {
    font-weight: bold;
    padding: 10px;
}
button.cancel {
    font-weight: bold;
    padding: 10px;
}

.buttons {
    margin: 10px auto;
    width: fit-content;
}    

input.gps-input {
    width: 75px;
}

input.phone-input {
    width: 105px;
}

.station-updates ul {
    list-style: none;
}
.station-view ul {
    margin: 0;
    padding: 0;
}
.station-view li {
    padding: 1px 0;
}

.station-info {
    border: 1px solid #654321;
    padding: 5px;
}

.react-leaflet-map {
    margin-top: 5px;
}

.stations-printout {
    color: black;
}

.stations-printout .header {
    font-weight: bold;
    font-size: 20px;
    padding: 2px 5px;
}

.stations-printout .license {
    margin: 0;
    padding-top: 5px;
    font-size: 14px;
}
.stations-printout .landscape {
    float: right;
    margin: 0;
    padding-top: 5px;
    font-size: 14px;
}

.station-printout {
    padding: 3px 0;
    font-size: 12px;
    border-bottom: 1px solid black;
    word-wrap: break-word;
}

.station-printout-header {
    font-weight: bold;
    border-bottom: 2px solid black;
}

/* --- These must add to 100% --- */
.station-printout .city {
    width: 15%;
    float: left;
    font-weight: bold;
}
.station-printout .brand {
    width: 12%;
    float: left;
}
.station-printout .octanes {
    width: 11%;
    float: left;
    text-align: center;
    font-weight: bold;
}
.station-printout .name {
    width: 21%;
    float: left;
}
.station-printout .streetaddress {
    width: 24%;
    float: left;
}
.station-printout .phone {
    width: 10%;
    float: left;
}
.station-printout .lastupdate {
    width: 7%;
    float: left;
}
/* --------------------------------- */

.station-printout .octanes .octane {
    padding:0 2px;
}

.reactivate {
    width: 800px;
    margin: 10px auto;
    border: 2px solid red;
    padding: 10px;
}
